.css-m9hi0w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-m9hi0w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
    color: #006adf !important;
}
.css-10ygbkt-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root:hover{
    transition: all .3s ease;
    background-color: #006adf !important;
    transform: scale(1.05);
}

.msgTo div:first-child{
    max-width: fit-content;
    /* margin-left: auto; */
}
.msgTo{
    margin-left: auto;
}

.msg div:first-child{
    font-weight: bold;
}
.msg{
	max-width: 60%;
	background: gainsboro;
	border-radius: 8px;
	padding: 10px 40px 10px 10px;
	margin-bottom: 11px;
}

@keyframes fadeIn {
    0%{
        transform: translateX(-40px);
        opacity: 0;
    }
    100%{
        transform: translateX(0);
        opacity: 1;
    }
}

.goIn{
    animation-name: fadeIn;
    animation-duration: .3s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
}
.editorClassName{
    padding: 0 20px;
    min-height: 300px;
}
.wrapperClassName{
    background-color: white;
    border-radius: 12px;
}
.toolbarClassName{
    border-radius: 12px 12px 0 0;
}